var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "background login_bg" }, [
    _c("div", { staticClass: "container" }, [
      _vm._m(0),
      _c("div", { staticClass: "box" }, [
        _c("img", {
          staticClass: "image",
          attrs: { src: require("../../../../assets/images/login_img.png") },
        }),
        _vm.flag
          ? _c(
              "div",
              { staticClass: "input_box" },
              [
                _c("span", { staticClass: "login_title" }, [
                  _vm._v("登录账号"),
                ]),
                _c("div", { staticClass: "inner_box margin_top" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.username,
                        expression: "username",
                      },
                    ],
                    staticClass: "input",
                    attrs: { placeholder: "输入用户名" },
                    domProps: { value: _vm.username },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.username = $event.target.value
                      },
                    },
                  }),
                ]),
                _c("div", { staticClass: "inner_box" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.password,
                        expression: "password",
                      },
                    ],
                    staticClass: "input",
                    attrs: { placeholder: "输入密码", type: "password" },
                    domProps: { value: _vm.password },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.password = $event.target.value
                      },
                    },
                  }),
                ]),
                _c(
                  "el-button",
                  {
                    staticClass: "submit",
                    attrs: { type: "primary" },
                    on: { click: _vm.onSubmit },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.loginEnter("loginData")
                      },
                    },
                  },
                  [_vm._v("登录")]
                ),
                _c("br"),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "title" }, [
      _c("span", { staticClass: "blod" }, [_vm._v("可达鸭")]),
      _vm._v(" | 加盟校管理系统 "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }