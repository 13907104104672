<template>
  <div class="background login_bg">
    <div class="container">
      <span class="title">
        <span class="blod">可达鸭</span> | 加盟校管理系统
      </span>

      <div class="box">
        <img class="image" src="../../../../assets/images/login_img.png" />

        <div class="input_box" v-if="flag">
          <span class="login_title">登录账号</span>

          <div class="inner_box margin_top">
            <!-- <img class="icon" src="./account.png" /> -->
            <input class="input" v-model="username" placeholder="输入用户名" />
          </div>

          <div class="inner_box">
            <!-- <img class="icon" src="./password.png" /> -->
            <input class="input" placeholder="输入密码" v-model="password" type="password" />
          </div>
          <!-- <el-select
            v-model="loginType" collapse-tags  placeholder="请选择要登录的系统" style="margin-top: 20px;border-radius: 20px;"
          >
            <el-option
              v-for="item in loginTypeList"
              :key="item.type"
              :label="item.title"
              :value="item.type"
            >
            </el-option>
          </el-select> -->
          <!-- <el-radio-group style="margin-top: 20px" v-model="loginType">
            <el-radio
              v-for="item in loginTypeList"
              :key="item.type"
              :label="item.type"
            >
            <span style="font-size:16px">{{item.title}}</span>
            </el-radio>
          </el-radio-group> -->
          <el-button
            class="submit"
            type="primary"
            @click="onSubmit"
            @keyup.enter.native="loginEnter('loginData')"
          >登录</el-button>
          <br />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { getListRouter, getListPerm } from '@/api/user/logo'
import { getListPerm } from '@/api/user/logo'
import { getMyInfo } from '@/api/user'
import {perms} from '@/settings/perms'
import {setCookies} from "../../../../utils/auth";
// import Cookies from "_js-cookie@2.2.1@js-cookie";
export default {
  name: 'login',
  data () {
    return {
      loginType:'cms',
      username: '',
      password: '',
      flag: true,
      loginPath: '',
      system: '',
      loginSkip: '',
      key: null,
      loginTypeList:[
        {
          type:'education',
          title:'备课授课'
        },
        {
          type:'merchant',
          title:'校区管理'
        }
      ]
    }
  },
  created () {
    // Cookies.remove('token')
    let _this = this
    document.onkeydown = function (e) {
      if (window.event === undefined) {
        this.key = e.keyCode
      } else {
        this.key = window.event.keyCode
      }
      if (this.key === 13) {
        _this.loginEnter('loginData')
      }
    }
    let tokenLose = _this.$route.query.tokenLose
    if (tokenLose) {
      this.$notify({
        title: '请注意',
        message: '验证失效请重新登录',
        type: 'warning'
      })
    }
  },
  methods: {
    // 登录回车事件
    loginEnter () {
      this.onSubmit()
    },
    onSubmit () {
      // if(this.loginType === '') {
      //  window.$msg('请先选择要登录的系统',2)
      //   return false
      // }
      let _this = this
      if (!_this.username || !_this.password) {
        window.$msg('用户名或密码不能为空', 1)
        return false
      } else {
        let formdatas = {
          loginName: _this.username,
          passWord: _this.password,
          systemCode: 'cms'
        }
        this.$store.dispatch('user/_login', formdatas)
          .then(res => {
            if (res.state === 'success') {
              document.onkeydown = undefined
              this.getListPerm()
              this.getListRouter()
              this.getMyInfo()
            }
          })
      }
    },

    // 获取路由菜单
    getListRouter () {
      this.$store.dispatch('user/addRoutes')
        .then(res => {
          if (res.state === 'success') {
            // this.$router.push(this.$route.query.redirect)
            this.$router.push(res.body[0].path)
          }
        })
    },

    // 获取按钮权限菜单
    getListPerm () {
      getListPerm(
        {
          systemCode:'cms'
        }
      ).then(res => {
        if (res.state === 'success') {
          localStorage.setItem('perms', JSON.stringify(res.body))
        }
        // TODO
        localStorage.setItem('perms', JSON.stringify(perms))
      })
    },

    // 获取个人信息
    getMyInfo () {
      getMyInfo().then(res => {
        if (res.state === 'success') {
          this.$store.commit('user/SET_PASSSAFE', res.body.passSafe)
          localStorage.setItem('passSafe',res.body.passSafe)
          localStorage.setItem('userName',res.body.loginName)
          localStorage.setItem('name',res.body.name)
          localStorage.setItem('userId',res.body.id)
          localStorage.setItem('platform','merchant')
          this.$store.commit('user/SET_NAME',res.body.loginName)
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.login_bg {
  background-image: url("../../../../assets/images/login_bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-width: 1000px;
}

.container {
  display: flex;
  flex-direction: column;
  width: 699px;
  height: 416px;
  background-color: #fff;
  border-radius: 15px;
}
.title {
  margin-top: 27px;
  margin-left: 27px;
  color: #fdbb10;
  font-size: 20px;
}

.blod {
  font-weight: 700;
}

.box {
  display: flex;
  flex-direction: row;
  width: 699px;
  height: 349px;
}

.image {
  width: 293px;
  height: 213px;
  margin-left: 40px;
  margin-top: 53px;
}

.input_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 53px;
  margin-right: 47px;
}

.login_title {
  font-size: 23px;
  color: #fdbb10;
}

.inner_box {
  display: flex;
  flex-direction: row;
  height: 42px;
  align-items: center;
  justify-content: center;
  padding: 4px;
  margin-top: 13px;
  border-bottom: 1px solid #dcdfe6;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.icon {
  width: 17px;
  height: 20px;
}

.input {
  width: 225px;
  border: none;
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}

.margin_top {
  margin-top: 45px;
}

.submit {
  width: 238px;
  margin-top: 20px;
  box-shadow: 0 2px 12px 0 rgba(245, 195, 25, 0.3);
  border-radius: 20px;
  border-color: #f7dc6e;
  background-image: linear-gradient(135deg, #f7dc6e 0%, #f5c319 100%);
}

.link {
  font-size: 14px;
  margin-right: 193px;
  margin-top: 7px;
}
</style>
